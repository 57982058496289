import * as React from "react"
import MainLayout from '../../layouts/main'
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../../components/Breadcrumbs";
import Partners from "../home/partners";
import ContributiFiscali from "../home/contributi-fiscali";
import Help from "../home/help";
import GalleryButton from "../../components/GalleryButton";
import HeroPage from "../../components/HeroPage";
import SectionInstallazioni from "../../components/SectionInstallazioni";
import SeoComponent from "../../layouts/partials/SeoComponent";

const items = [
    { title: 'Prodotti', href: 'prodotti' },
    { title: 'Arredamento di interni' }
]
const installazioni = [
    {
        name: 'Le nostre installazioni',
        gallery: 'tendaggi-interni-01.jpg',
        list: [
            {
                id: '1',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/13.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/13.jpg',
            },
            {
                id: '2',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/14.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/14.jpg',
            },
            {
                id: '3',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/15.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/15.jpg',
            },
            {
                id: '4',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/16.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/16.jpg',
            },
            {
                id: '5',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/12.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/12.jpg',
            },
            {
                id: '6',
                src: '/prodotti/tessutitendaggi/tendaggioInterno/11.jpg',
                thumb: '/thumb/tessutitendaggi/tendaggioInterno/11.jpg',
            },
        ]
    },
]

const ArredamentoInterni = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(dettaglio)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]        
                        placeholder: NONE       
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [products, setProducts] = useState({});
    useEffect(() => {
        setProducts({
            prodotti: [
                {
                    name: 'Tendaggi',
                    gallery: 'tendaggi.jpg',
                    items: [
                        {
                            id: 'v1',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/IMG_4602.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/IMG_4602.jpg',
                        },
                        {
                            id: '1',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/01.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/01.jpg',
                        },
                        {
                            id: '2',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/02.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/02.jpg',
                        },
                        {
                            id: '3',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/03.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/03.jpg',
                        },
                        {
                            id: '4',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/04.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/04.jpg',
                        },
                        {
                            id: '5',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/05.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/05.jpg',
                        },
                        {
                            id: '6',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/06.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/06.jpg',
                        },
                        {
                            id: '7',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/07.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/07.jpg',
                        },
                        {
                            id: '8',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/08.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/08.jpg',
                        },
                        {
                            id: '9',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/09.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/09.jpg',
                        },
                        {
                            id: '10',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/10.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/10.jpg',
                        },
                        {
                            id: '11',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/17.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/17.jpg',
                        },
                    ]
                },
                {
                    name: 'Tessuti 1',
                    gallery: 'tessuti-02.jpg',
                    items: [
                        {
                            id: '12',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/18.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/18.jpg',
                        },
                        {
                            id: '13',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/19.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/19.jpg',
                        },
                        {
                            id: '14',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/20.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/20.jpg',
                        },
                        {
                            id: '15',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/21.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/21.jpg',
                        },
                        {
                            id: '16',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/22.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/22.jpg',
                        },
                        {
                            id: '17',
                            src: '/prodotti/tessutitendaggi/tendaggioInterno/23.jpg',
                            thumb: '/thumb/tessutitendaggi/tendaggioInterno/23.jpg',
                        },
                        {
                            id: '18',
                            src: '/prodotti/tessutitendaggi/ambra_tessuti-arredo_2.jpg',
                            thumb: '/thumb/tessutitendaggi/ambra_tessuti-arredo_2.jpg',
                        },
                        {
                            id: '19',
                            src: '/prodotti/tessutitendaggi/king-velluto_arredamenti_2.jpg',
                            thumb: '/thumb/tessutitendaggi/king-velluto_arredamenti_2.jpg',
                        },
                        {
                            id: '20',
                            src: '/prodotti/tessutitendaggi/lino-ricamato_tessuti-provenzali_2.jpg',
                            thumb: '/thumb/tessutitendaggi/lino-ricamato_tessuti-provenzali_2.jpg',
                        },
                        {
                            id: '21',
                            src: '/prodotti/tessutitendaggi/metropolis-simta_tessuti-arredo_2.jpg',
                            thumb: '/thumb/tessutitendaggi/metropolis-simta_tessuti-arredo_2.jpg',
                        },
                        {
                            id: '22',
                            src: '/prodotti/tessutitendaggi/tenda-in-velo_tende-da-interni_1.jpg',
                            thumb: '/thumb/tessutitendaggi/tenda-in-velo_tende-da-interni_1.jpg',
                        },
                    ]
                },
                {
                    name: 'Tessuti',
                    gallery: 'tessuti.jpg',
                    items: [
                        {
                            id: '23',
                            src: '/prodotti/tessutitendaggi/tenda-motorizzata_tendaggi_1.jpg',
                            thumb: '/thumb/tessutitendaggi/tenda-motorizzata_tendaggi_1.jpg',
                        },
                        {
                            id: '24',
                            src: '/prodotti/tessutitendaggi/tenda-motorizzata_tende-da-sole-pisa_2.jpg',
                            thumb: '/thumb/tessutitendaggi/tenda-motorizzata_tende-da-sole-pisa_2.jpg',
                        },
                        {
                            id: '25',
                            src: '/prodotti/tessutitendaggi/tenda-su-misura_tende-da-interni_2.jpg',
                            thumb: '/thumb/tessutitendaggi/tenda-su-misura_tende-da-interni_2.jpg',
                        },
                        {
                            id: '26',
                            src: '/prodotti/tessutitendaggi/tessuto-poliestere_arredamento-moderno_2.jpg',
                            thumb: '/thumb/tessutitendaggi/tessuto-poliestere_arredamento-moderno_2.jpg',
                        },
                        {
                            id: '27',
                            src: '/prodotti/tessutitendaggi/tessuto-raso_tessuti-arredo-pisa_2.jpg',
                            thumb: '/thumb/tessutitendaggi/tessuto-raso_tessuti-arredo-pisa_2.jpg',
                        },
                    ]
                },
            ],
        })
    }, [gContext])
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'Gamma_tessuti_da_interno.jpg')}
                imageAlt={'Gamma_tessuti_da_interno.jpg'}
                title={'Arredamento di interni'}
            />
            <Breadcrumps  items={items}/>

            <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <div className="w-full text-justify" dangerouslySetInnerHTML={{ __html: gContext.t('interni.intro') }}></div>

                <SectionInstallazioni items={installazioni} images={images} classes={'mb-16'}/>

                <h2 className="title-section-light text-center mb-16">{gContext.t('Tutte le tipologie disponibili')}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 sm:gap-y-16 mb-8">
                    {(products?.prodotti || []).map((item) => (
                        <div key={item.name} className="min-h-[300px]">
                            <GalleryButton image={gContext.getImageFromName(images, item.gallery)} imageAlt={`image`} items={item.items} />
                        </div>
                    ))}
                </div>
            </div>


            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default ArredamentoInterni

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)